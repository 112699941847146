<template>
  <div id="compbox">
    <div class="hadheight"></div>
    <Header />
    <!-- 退货进度 -->
    <div class="salesbox">
        <div class="basecont">
            <div class="cont">
                <div class="lt">
                    <div class="fz_title">
                        <div class="ft1">
                            <h1 class="f_tit basefont30">退货进度</h1>
                        </div>
                        <router-link to="/my/myorder" @click="$router.back();" class="but">
                            <i><img src="../../assets/images/returniconig1.png" alt=""></i>
                            <span>返回订单列表</span>
                        </router-link>
                    </div>
                </div>
            </div>
            <div class="cont">
                <div class="lt">
                    <div class="progress_list">
                        <template v-for="(item,index) in process">
                            <div class="li"
                                :key="index">
                                <div v-if="item.refundState > 3">
                                    <div class="f_tit basefont24">
                                        {{item.refundStateDesc}}
                                    </div>
                                    <div class="f_text">
                                        {{item.remark}} {{item.processDate}}
                                    </div>
                                </div>
                                <div v-if="item.refundState==3 || item.refundState==2">
                                    <div class="f_tit basefont24">
                                       {{item.refundStateDesc}}
                                    </div>
                                    <div class="f_text">
                                        {{item.processDate}}
                                    </div>
                                    <div class="upfile">
                                        <div class="f_file">
                                            <div class="f_nei">
                                                <i></i>
                                                <span>上传快递单号图片</span>
                                            </div>
                                            <img :src="oddimg" v-show="oddimg!=''" alt="">
                                            <input type="file" id="upload">
                                        </div>
                                        <a href="javascript:;" @click="wujud=true" class="hm-but f_but">
                                            <span>确认上传</span>
                                        </a>
                                    </div>
                                    <div class="f_text">
                                        {{item.remark}}
                                    </div>
                                </div>
                                <div v-if="item.refundState==1">
                                    <div class="f_tit basefont24">
                                        {{item.refundStateDesc}}
                                    </div>
                                    <div class="f_text f_text2">
                                        {{item.remark}} <router-link to="/contact">联系客服</router-link>
                                    </div>
                                    <div class="f_text">
                                        商家同意退款。   {{item.processDate}}
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="shopcart_sign_cont">
                        <div class="item" v-for="(item,index) in orderMsg.orderItemInfoList" :key="index">
                            
                            <div class="img">
                                <img :src="item.skuDefaultImg" :alt="item.skuName">
                            </div>
                            <div class="font">
                                <div class="tit">
                                    <div class="name">
                                        {{item.skuName}}
                                    </div>
                                </div>
                                <div class="text">{{item.skuDesc}}</div>
                                <div class="f_ne">
                                    <div class="fw_zen"></div>
                                    <a href="javascript:;"  class="jia">
                                        ¥ {{item.retailPrice}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="progress_class">
                        <div class="li basefont24" >
                            <div class="tit">
                                <span>选择退货类型</span>
                            </div>
                            <div class="f_text">退货退款</div>
                        </div>
                        <div class="li basefont24">
                            <div class="tit">
                                <span>退货原因</span>
                            </div>
                            <div class="f_text">商品错发、漏发</div>
                        </div>
                    </div>
                </div>
                <div class="gt">
                   <div class="progr_gt_nei">
                        <div class="tit basefont24">退货进度</div>
                        <div class="progr_jut">
                            <template v-for="(item,index) in process">
                    
                                <div class="li basefont18"
                                    v-if="item.refundState>1"
                                    :key="index">
                                    <div class="f_tit ">{{item.refundStateDesc}}</div>
                                    <div class="f_text">
                                        <p>{{item.remark}} {{item.processDate}}</p>
                                    </div>
                                </div>
                                <div class="li basefont18" 
                                    v-if="item.refundState==1" 
                                    :key="index">
                                    <div class="f_tit ">{{item.refundStateDesc}}</div>
                                    <div class="f_text">
                                        <p>预计7个工作日内处理完成，请您耐心等待。
                                            <router-link to="/contact">联系客服</router-link>
                                        </p>
                                    </div>
                                </div>
                                <div class="li basefont18" 
                                    v-if="item.refundState==0" 
                                    :key="index">
                                    <div class="f_text">
                                        <p>商家同意退款</p>
                                        <p>{{item.processDate}}</p>
                                    </div>
                                </div>
                            </template>
                        </div>
                        <div class="shu basefont24" style="font-weight: bold;margin-top: 26px">
                            退款金额： ￥{{orderMsg.actualPrice}}
                        </div>
                        <div class="progr_but" v-if="process[0].refundState!=5">
                            <!-- <a href="javascript:;" class="hm-but f_but">
                                <span>取消退货</span>
                            </a> -->
                            <!-- <a href="javascript:;" class="f_but2">
                                <span>申诉</span>
                            </a> -->
                        </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
    <!-- 订单号填写 -->
    <transition name="fade">
        <div class="wufixed" v-show="wujud">
            <div class="bag" @click="altHied"></div>
            <div class="cont">
                <a href="javascript:;" class="close" @click="altHied"><img src="../../assets/images/closeiconf1.png" alt=""></a>
                <div class="font">
                    <div class="tit">订单号填写</div>
                    <div class="text">
                        只需输入您在完成结账时（以及在您的订单确认电子邮件中）提供的订单号。
                    </div>
                </div>
                <div class="form">
                    <input type="text" v-model="expressNumber" placeholder="物流编号：">
                    <a href="javascript:;" @click="uploadExImage" class="hm-but f_but">
                        <span>提交申请</span>
                        <i>
                            <img src="../../assets/images/jiantouyo1.png" >
                            <img src="../../assets/images/jiantouyo2.png" >
                        </i>
                    </a>
                </div>
            </div>
        </div>
    </transition>
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
const OSS = require('ali-oss');
export default {
  components: {
    Header,
    Footer
  },
  data(){
    return {
        oddimg: '',  //订单图片
        expressNumber: "",  //快递单号
        process: {},
        orderMsg: {},
        wujud: false
    }
  },
  mounted(){
    /* 获取退款流程信息 */
    this.getRefundProcess();
    /* 获取订单信息 */
    this.getOrderInfo();
  },
  methods: {
    // 退款流程信息
    getRefundProcess(){
        let orderId = this.$route.params.id;
        this.$axios.post("/api2/portal/auth/order/refund/getOrderRefundProcess",{orderId}).then(res => {
         
            if(res.code=="000000"){
                // res.data.unshift({
                //     refundState: "2",
                //     refundStateDesc: "状态",
                //     processDate: "日期",
                //     remark: "描述"
                // });
                // res.data.unshift({
                //     refundState: "3",
                //     refundStateDesc: "状态",
                //     processDate: "日期",
                //     remark: "描述"
                // });
                this.process = res.data;
                /* 快递单上传 */
                let jud = this.process.find(v => {
                    return v.refundState == 3 || v.refundState == 2;
                });
                if(jud){
                    this.$nextTick(() => {
                        console.log(100);
                        this.uploadFile();
                    });
                }
            }
        }).catch(error => {
            console.log(error);
        });
    },
    // 上传快递图片  快递单号  
    uploadExImage(){
        let orderId = this.$route.params.id;
        let expressNumber = this.expressNumber;
        let expressNumberImgUrl = this.oddimg;
        if(expressNumberImgUrl==""){
            return this.$message({
                    message: "请上传快递相关图片！",
                    type: "error"
                });;
        }
        if(expressNumber==""){
            return this.$message({
                    message: "快递单号不能为空！",
                    type: "error"
                });;
        }
        this.$axios.post("/api2/portal/auth/order/refund/uploadRefundExpressNumber",{
            orderId,
            expressNumber,
            expressNumberImgUrl
        }).then(res => {
            console.log(res);
            if(res.code == "000000"){
                this.$message({
                    message: "提交成功",
                    type: "success"
                });
                this.altHied();
            }else{
                this.$message({
                    message: res.message,
                    type: "error"
                });
            }
        }).catch(error => {
            console.log(error);
        });
    },
    getOrderInfo(){
        let orderNo = this.$route.params.id;
        this.$axios.post("/api2/portal/order/detail/getOrderNo",{orderNo}).then(res => {
            console.log(res);
            if(res.code=="000000"){
                this.orderMsg = res.data;
            }
        }).catch(error => {
            console.log(error);
        });
    },
    altHied(){
        this.wujud = false;
    },
    // 图片上传
    uploadFile(){
        /* 获取上传OSStoken */
        const _this =this;
        let client;
        _this.$axios.post('/api2/portal/storage/file/fileInfo/getToken').then(res=>{
            this.uploadToken = res.data;
             client = new OSS({
                // yourRegion填写Bucket所在地域。以华东1（杭州）为例，yourRegion填写为oss-cn-hangzhou。
                region: this.uploadToken.region,
                // 从STS服务获取的临时访问密钥（AccessKey ID和AccessKey Secret）。
                accessKeyId: this.uploadToken.accessKeyId,
                accessKeySecret: this.uploadToken.accessKeySecret,
                // 从STS服务获取的安全令牌（SecurityToken）。
                stsToken: this.uploadToken.securityToken,
                // 填写Bucket名称。
                bucket: this.uploadToken.bucketName
            });
        }).catch(error=>{
            console.log(error);
        });
        

      // 从输入框获取file对象，例如<input type="file" id="file" />。
      let data;
      // 创建并填写Blob数据。
      //const data = new Blob(['Hello OSS']);
      // 创建并填写OSS Buffer内容。
      //const data = new OSS.Buffer(['Hello OSS']);

      const upload = document.getElementById("upload");

       async function putObject (data,str1) {
         try {
           // 填写Object完整路径。Object完整路径中不能包含Bucket名称。
           // 您可以通过自定义文件名（例如exampleobject.txt）或文件完整路径（例如exampledir/exampleobject.txt）的形式实现将数据上传到当前Bucket或Bucket中的指定目录。
           // data对象可以自定义为file对象、Blob数据或者OSS Buffer。
          const result = await client.put(
            str1,
            data
            //{headers}
          );
          _this.oddimg=result.url;
          console.log(_this.oddimg);
        } catch (e) {
          console.log(e);
        }
      }

      upload.addEventListener("change", () => {
        data = document.getElementById("upload").files[0];
        var imgSize = data.size;  //b
        if(imgSize>1024*1024*10){//10M
            return this.$message.error('上传图片不能超过1M');
        }
        if(data.type != 'image/png' &&
         data.type != 'image/jpeg' &&
          data.type != 'image/gif'){
            return this.$message.error('图片上传格式不正确');
        }
        var in1 = data.name.lastIndexOf(".");
        var str1 = data.name.substring(in1);
        var date = "gzc" + new Date().getTime() + str1;
        console.log(date,date);
        putObject(data,date);
      });
    }
  }
}
</script>

<style scoped>
.fade-enter-active,.fade-leave-active{
    transition: all 0.4s;
}
.fade-enter,.fade-leave-to{
    opacity: 0;
}

</style>